<template>
  <div class="inner">
    <!-- <van-button >内页</van-button> -->
    <!-- <p ref="detail" v-html="list.contentDetail " style="padding:5px"></p> -->
    
    <router-view />
  </div>
</template>

<script>
// @ is an alias to /src
import { Button } from 'vant'
import axios from "axios";


export default {
  name: 'Home',
  components: {
    [Button.name]: Button
  },
  data(){
    return{
      list:{}
    }
  },
  methods:{
    // getdata(){
    //   const url="https://das.boxuanedu.cn/dd/rest/courseDetail/export"
    //   axios.post(url,{
    //     pageNum: 20,
    //     startPage: 0
    //   }).then(res=>{
    //     this.list=res.data.data.list[13]
    //   }).catch(err=>console.log(err))
    // }
  },
  mounted(){
    // this.getdata()
    // setTimeout(() => {
    //   let a=this.$refs.detail
    //   a.children[0].style.textAlign="left";
    //   console.log(a.children[0].style.textAlign);
    // console.log(a.children[0].style.width="");
    
    // }, 300);
    
    
  },
  activated () {
    this.$navBar.onLeftClick(function (next) {
      next(false)
      console.log('左键点击，取消默认返回')
    })
  }
}
</script>

<style lang="less" scoped>
.inner{
  overflow: auto !important ;
}
</style>
